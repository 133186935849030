<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <iframe v-if="src" width="100%" height="100%" :src="src" style="border:0" />
    <v-container v-else class="loader-wrapper">
      <v-progress-circular indeterminate color="primary" />
    </v-container>
  </div>
</template>

<script>
  import * as Mediateam from '@/api/mediateam'

  export default {
    name: 'MediateamWrapper',
    props: {
      target: String
    },
    data() {
      return {
        src: null,
        title: 'Mediateam'
      }
    },
    async created() {
      if (this.target === 'patients') {
        this.title = 'Dossiers cliniques'
      } else {
        this.title = 'Guichet d\'orientation'
      }

      await Mediateam.authenticate().then(({data}) => {
        let host = data.host
        let token = data.token
        if (this.target === 'patients') {
          this.src = host + '/front/#/general/contextualDisplay/p1/' + token + '/p2/BENEFICIARY_FOLDER_LIST'
        }
        else if (this.target === 'guidance') {
          this.src = host + '/front/#/general/contextualDisplay/p1/' + token + '/p2/GUIDANCE_LIST'
        }
      }).catch(() => {
        this.$router.back()
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'error',
          title: 'Une erreur est survenue',
          subtitle: 'Vous n\'exercez pas dans un département ou une région desservie par le service Mediateam.'
        })
      })
    }
  }
</script>

<style lang="scss" scoped>
    iframe {
        height: calc(100vh - 85px)
    }
    .loader-wrapper {
        height: 100vh;
        align-items: center;
        display: flex;
        justify-content: center;
    }
</style>
