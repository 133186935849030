import api from './api'

// @deprecated
export function authenticate() {
  return api.get('/v2/mediateam/authenticate')
}

// @deprecated
export function getFolderState(patientUid) {
  return api.get('/v2/mediateam/patients/'+patientUid+'/folder-state')
}

// @deprecated
export function getFolderLink(patientUid) {
  return api.get('/v2/mediateam/patients/'+patientUid+'/folder-link')
}
